@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('./Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url('./Montserrat-SemiBold.ttf') format('truetype');
}
